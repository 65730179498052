import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import View from "../components/View"
import Status from "../components/Status"

const Index = () => (
  <Layout>
    <Status />
    <View title="Simple Gatsby Starter (with auth) ">
      <p>
        This is a simple example of creating dynamic apps with Gatsby that
        require user authentication. It uses concepts from the
        {` `}
        <a href="https://www.gatsbyjs.org/docs/building-apps-with-gatsby/#client-only-routes">
          client-only routes section
        </a>
        {` `}
        of the “Building Apps with Gatsby” documentation.
      </p>
      <p>
        For the full experience, go to
        {` `}
        <Link to="/app/profile">your profile</Link>.
      </p>
      <br /><br />
      <p>
        Read{' '}
        <a href="https://blog.appseed.us/gatsby-authentication-starter">
        {' '}more{' '}  
        </a>
        {' '}or{' '} 
        <a href="https://github.com/rosoftdeveloper/starter-gatsbyjs-auth">
        Get Sources 
        </a>{' '} (Deploy Script included) 
      </p>
      <p>
        Provided by {' '}
        <a href="https://appseed.us">
          @AppSeed.us
        </a>
      </p>
      </View>
  </Layout>
)

export default Index
